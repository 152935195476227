// base url
// export const BASE_URL: string = 'http://localhost:3000'; //dev

// export const BASE_URL: string = 'http://ec2-13-233-167-35.ap-south-1.compute.amazonaws.com' // test

export const BASE_URL: string =
  'https://www.pay-retail.com';

// default dist code
export const GM0001: string = 'GM0001';

// local db tag
export const TAG_TOKEN: string = 'token';
export const TAG_USERNAME: string = 'username';
export const TAG_ROLE: string = 'role';
export const TAG_MERCHANTCODE: string = 'merchantcode';
export const TAG_DISTRIBUTORCODE: string = 'distributorcode';
export const TAG_TRADENAME: string = 'tradename';

// header key for token
export const HEADER_TOKEN: string = 'auth-token';

// CONSTANTS
export const Role: any = {
  ADMIN: '1',
  DISTRIBUTOR: '4',
  SUBDISTRIBUTOR: '3',
  MERCHANT: '2',
  SUPERADMIN: '5',
};

export const Action: any = {
  NEW_DIST: 'newDistributor',
  ADD_DIST_USER: 'addUsertoDistributor',
  NEW_SUBD: 'newSubDistributor',
  ADD_SUBD_USER: 'addUserToSubDistributor',
  NEW_MERC: 'newMerchant',
  ADD_MERC_TO_DIST: 'addMerchanttoDistributor',
  ADD_MERC_TO_SUBD: 'addMerchantToSubDistributor',
};

export const Status: any = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  ON_PROCESS: 'On Process',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};


export const Source: any = {
  WEB: 'WEB',
  DEV: 'DEV',
};
