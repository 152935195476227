import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { User } from '../models/user';
import {
  BASE_URL,
  HEADER_TOKEN,
  TAG_TOKEN,
  TAG_USERNAME,
  Source,
} from '../models/config';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  baseUrl = BASE_URL;

  navValues = [];

  get(offset, limit, filter?, name?, sort?) {
    let headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append(HEADER_TOKEN, localStorage.getItem(TAG_TOKEN));
    let params = new HttpParams()
      .append('offset', offset)
      .append('limit', limit);

    if (name != null && name != '') {
      params = params.append('search', name);
    }

    if (filter != null && filter != '') {
      Object.entries(filter).forEach(
        (item) => (params = params.append(item[0], item[1].toString()))
      );
    }

    if (sort != null && sort != '') {
      params = params.append('sort', sort);
    }

    return this.http.get(`${this.baseUrl}/api/user`, {
      headers: headers,
      params: params,
    });
  }

  getV2(offset, limit, filter?, name?, sort?) {
    let headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append(HEADER_TOKEN, localStorage.getItem(TAG_TOKEN));
    let params = new HttpParams()
      .append('offset', offset)
      .append('limit', limit);

    if (name != null && name != '') {
      params = params.append('search', name);
    }

    if (filter != null && filter !== '') {
      Object.entries(filter).forEach(
        (item) => (params = params.append(item[0], item[1].toString()))
      );
    }

    if (sort != null && sort != '') {
      params = params.append('sort', sort);
    }

    return this.http.get(`${this.baseUrl}/api/user/v2`, {
      headers: headers,
      params: params,
    });
  }

  getById(id) {
    let headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append(HEADER_TOKEN, localStorage.getItem(TAG_TOKEN));

    return this.http.get(`${this.baseUrl}/api/user/${id}`, {
      headers: headers,
    });
  }

  create(user: User) {
    let headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append(HEADER_TOKEN, localStorage.getItem(TAG_TOKEN));
    let c = user;

    console.log('user', c);
    return this.http.post(`${this.baseUrl}/api/user`, c, {
      headers: headers,
      responseType: 'text',
    });
  }

  login(username: string, password: string) {
    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/json'
    );
    const body = {
      username: username,
      password: password,
      source: Source.WEB,
    };
    return this.http.post(`${this.baseUrl}/api/user/login`, body, {
      headers: headers,
      responseType: 'text',
    });
  }

  update(user: User) {
    let headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append(HEADER_TOKEN, localStorage.getItem(TAG_TOKEN));
    let c = user;
    return this.http.put(`${this.baseUrl}/api/user`, c, {
      headers: headers,
      responseType: 'text',
    });
  }

  delete(id) {
    console.log(id);
    let headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append(HEADER_TOKEN, localStorage.getItem(TAG_TOKEN));
    return this.http.delete(`${this.baseUrl}/api/user/${id}`, {
      headers: headers,
      responseType: 'text',
    });
  }

  getLoggedIn() {
    let headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append(HEADER_TOKEN, localStorage.getItem(TAG_TOKEN));

    const username = localStorage.getItem(TAG_USERNAME);
    return this.http.get(`${this.baseUrl}/api/user/${username}`, {
      headers: headers,
    });
  }
}
