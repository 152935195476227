import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { TAG_TOKEN, TAG_USERNAME } from '../models/config';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, public service: UserService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | import('@angular/router').UrlTree
    | import('rxjs').Observable<boolean | import('@angular/router').UrlTree>
    | Promise<boolean | import('@angular/router').UrlTree> {
    
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem(TAG_TOKEN);
      if (!token) {
        this.router.navigate(['login']);
        return reject(false);
      }

      // get user permission
      const username = localStorage.getItem(TAG_USERNAME);
      this.service.getById(username).subscribe((data) => {
        let modules = []
        // console.log('user', data['userRole']['allowedModules']);
        data['userRole']['allowedModules'].map(mod => {
          modules.push(mod.name)
        })
        // console.log(modules)
        // console.log('route', route['routeConfig']['path'])
        if (!modules.includes(route['routeConfig']['path'])){
          this.router.navigate(['home']);
          return reject(false);
        }
      });

      return resolve(true)

    });
  }
}
