import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TAG_TOKEN, TAG_USERNAME, TAG_ROLE, TAG_TRADENAME, Role } from './models/config';
import { UserService } from './services/user.service';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  constructor(private router: Router, public service: UserService) {
      this.router.events.subscribe(val => {
          window.scrollTo({ top: 0, behavior: 'smooth' })
      })
  }

  disabled = false
  title = 'Grainsmart';
  // navigations: any;

  year: string = '';

  ngOnInit(): void {
    this.year = new Date().toISOString().substring(0, 4);

    // console.log(this.router);

    $('#sidebarToggle, #sidebarToggleTop').on('click', function (e) {
      $('body').toggleClass('sidebar-toggled');
      $('.sidebar').toggleClass('toggled');
      if ($('.sidebar').hasClass('toggled')) {
        $('.sidebar .collapse').collapse('hide');
      }
    });

    // get user permission
    const username = localStorage.getItem(TAG_USERNAME);
    if(username) {
      this.service.getById(username).subscribe((data) => {
        let modules = []
        let modulesAdd = []
        data['userRole']['allowedModules'].map(mod => {
          if(mod['permission']['view']) modules.push(mod['name'])
          if(mod['permission']['add']) modulesAdd.push(mod['name'])
        }) 

        this.service.navValues = [
          {
            group: 'Records',
            contents: [
              {
                section: 'Users',
                icon: 'users',
                tag: 'account',
                pages: [
                  {
                    label: 'Create Shop',
                    path: '/merchant/new',
                    sub: [],
                    disabled: !modulesAdd.includes('merchant')
                  },
                  {
                    label: 'Distributors',
                    path: '/merchant/4/list',
                    sub: [],
                    disabled: !modules.includes('merchant') || !this.isAdmin()
                  },
                  {
                    label: 'Sub-Distributors',
                    path: '/merchant/3/list',
                    sub: [],
                    disabled: !modules.includes('merchant') || !this.isAdmin()
                  },
                  {
                    label: 'Merchant',
                    path: '/merchant/2/list',
                    sub: [],
                    disabled: !modules.includes('merchant')
                  },
                  {
                    label: 'User Access',
                    path: '/user',
                    sub: [],
                    disabled: !modules.includes('user')
                  },
                ],
              },
              {
                section: 'ECPay Modules',
                icon: 'money-bill-wave',
                tag: 'ecpay',
                pages: [
                  {
                    label: 'ECBills',
                    path: '/biller/BIL/list',
                    sub: [],
                    disabled: !modules.includes('biller') || !this.isAdmin()
                  },
                  {
                    label: 'ECCash',
                    path: '/biller/CAS/list',
                    sub: [],
                    disabled: !modules.includes('biller') || !this.isAdmin()
                  },
                  {
                    label: 'ECLoad',
                    path: '/biller/LOD/list',
                    disabled: !modules.includes('biller') || !this.isAdmin(),
                    sub: [
                      {
                        label: 'Telcom',
                        path: '/biller/telcom',
                        disabled: !modules.includes('biller')
                      },
                      {
                        label: 'Brand',
                        path: '/biller/brand',
                        disabled: !modules.includes('biller')
                      },
                      {
                        label: 'Item',
                        path: '/biller/item',
                        disabled: !modules.includes('biller')
                      },
                      {
                        label: 'Plan',
                        path: '/biller/plan',
                        disabled: !modules.includes('biller')
                      },
                    ],
                  },
                  {
                    label: 'ECPins',
                    path: '/biller/PIN/list',
                    sub: [],
                    disabled: !modules.includes('biller') || !this.isAdmin()
                  },
      
                  {
                    label: 'Category',
                    path: '/billercategory',
                    sub: [],
                    disabled: !modules.includes('biller') || !this.isAdmin()
                  },
                ],
              },
              {
                section: 'PayRetail Apps',
                icon: 'tags',
                tag: 'payretail',
                pages: [
                  {
                    label: 'Retail Management',
                    path: '/retailmanagement',
                    sub: [],
                    disabled: !modules.includes('retailmanagement')
                  },
                  {
                    label: 'Human Resources',
                    path: '/humanresource',
                    sub: [],
                    disabled: !modules.includes('humanresource')
                  },
                ],
              },
              {
                section: 'Accounting',
                icon: 'calculator',
                tag: 'accounting',
                pages: [
                  {
                    label: 'Transaction',
                    path: '/transaction',
                    sub: [],
                    disabled: !modules.includes('transaction')
                  },
                  {
                    label: 'Manual Transactions',
                    path: '/meralco',
                    sub: [],
                    disabled: !modules.includes('manual')
                  },
                  {
                    label: 'Wallet',
                    path: '/wallet',
                    sub: [],
                    disabled: !modules.includes('wallet')
                  },
                  {
                    label: 'Reports',
                    path: '/report',
                    sub: [],
                    disabled: !modules.includes('report')
                  },
                ],
              },
              {
                section: 'Security',
                icon: 'shield-alt',
                tag: 'security',
                pages: [
                  {
                    label: 'Activity Logs',
                    path: '/log',
                    sub: [],
                    disabled: !modules.includes('log') || !this.isAdmin()
                  },
                  {
                    label: 'Customer Service',
                    path: '/ticket',
                    sub: [],
                    disabled: !modules.includes('log') || !this.isAdmin()
                  },
                  // {
                  //   label: 'Permission',
                  //   path: '/role',
                  //   sub: [],
                  // },
                ],
              },
            ],
          },
          {
            group: 'Actions',
            contents: [
              {
                section: 'Settings',
                icon: 'cogs',
                tag: 'help',
                pages: [
                  {
                    label: 'Bank Accounts',
                    path: '/bankaccount',
                    sub: [],
                    disabled: !modules.includes('bankaccount')
                  },
                  {
                    label: 'Announcement',
                    path: '/announcement',
                    sub: [],
                    disabled: !modules.includes('announcement') || !this.isAdmin()
                  },
                  // {
                  //   label: 'FAQs',
                  //   path: '/user',
                  //   sub: [],
                  //   disabled: !modules.includes('user')
                  // },
                  {
                    label: 'App Versions',
                    path: '/about',
                    sub: [],
                    disabled: !modules.includes('about')
                  },
                  // {
                  //   label: 'System Maintenance',
                  //   path: '/maintenance',
                  //   sub: [],
                  //   // disabled: !modules.includes('about')
                  // },
                ],
              },
            ],
          },
        ];
      });
    }
  }


  isLoginScreen(): boolean {
    return (
      this.router.url == '' ||
      this.router.url.startsWith('/login') ||
      this.router.url.startsWith('/change-password') ||
      !this.router.url
    );
  }

  logout(): void {
    localStorage.clear();
    this.router.navigate(['login']);
    this.service.navValues = [];
  }

  getUsername(): string {
    return localStorage.getItem(TAG_USERNAME) || 'You are not logged in';
  }

  getTradeName(): string {
    const tradeName = localStorage.getItem(TAG_TRADENAME)

    return  tradeName == '' || tradeName == 'null' ? '' : tradeName
  }

  isAdmin(): boolean {
    return localStorage.getItem(TAG_ROLE) == Role.ADMIN
  }

  isDistributor(): boolean {
    return localStorage.getItem(TAG_ROLE) == Role.DISTRIBUTOR
  }
}
