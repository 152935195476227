import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'merchant',
    loadChildren: () =>
      import('./merchant/merchant.module').then((m) => m.MerchantModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'biller',
    loadChildren: () =>
      import('./biller/biller.module').then((m) => m.BillerModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'billercategory',
    loadChildren: () =>
      import('./biller-category/biller-category.module').then(
        (m) => m.BillerCategoryModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'role',
    loadChildren: () => import('./role/role.module').then((m) => m.RoleModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'terminal',
    loadChildren: () =>
      import('./terminal/terminal.module').then((m) => m.TerminalModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'humanresource',
    loadChildren: () =>
      import('./human-resource/human-resource.module').then(
        (m) => m.HumanResourceModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'retailmanagement',
    loadChildren: () =>
      import('./retail-management/retail-management.module').then(
        (m) => m.RetailManagementModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./about/about.module').then((m) => m.AboutModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'announcement',
    loadChildren: () =>
      import('./announcement/announcement.module').then(
        (m) => m.AnnouncementModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'wallet',
    loadChildren: () =>
      import('./wallet/wallet.module').then((m) => m.WalletModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'transaction',
    loadChildren: () =>
      import('./transaction/transaction.module').then(
        (m) => m.TransactionModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'meralco',
    loadChildren: () =>
      import('./transaction-meralco/transaction-meralco.module').then(
        (m) => m.TransactionMeralcoModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'report',
    loadChildren: () =>
      import('./report/report.module').then((m) => m.ReportModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'bankaccount',
    loadChildren: () =>
      import('./bank-account/bank-account.module').then(
        (m) => m.BankAccountModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'log',
    loadChildren: () => import('./log/log.module').then((m) => m.LogModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'ticket',
    loadChildren: () => import('./ticket/ticket.module').then((m) => m.TicketModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./maintenance/maintenance.module').then((m) => m.MaintenanceModule),
    canActivate: [AuthGuardService],
  },
  { 
    path: 'change-password', 
    loadChildren: () => import('./change-password/change-password.module').then(m => m.ChangePasswordModule) 
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
})
export class AppRoutingModule {}
