<!-- <div class="container-fluid">
  <div class="row">
    <div class="col-lg-2">
      <ul class="nav flex-column">
        <li class="nav-item" *ngFor="let route of routes; let i=index">
          <a class="nav-link active" [routerLink]="route.path">{{route.label}}</a>
        </li>
      </ul>
    </div>
    <div class="col-lg-10">
      <router-outlet></router-outlet>
      <app-toasts></app-toasts>
    </div>
  </div>
</div> -->

<div id="wrapper">
  <ul
    *ngIf="!isLoginScreen()"
    class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    id="accordionSidebar"
  >
    <!-- Sidebar - Brand -->
    <a
      class="sidebar-brand d-flex align-items-center justify-content-center"
      routerLink="home"
    >
      <div class="sidebar-brand-icon rotate-n-15">
        <i class="fas fa-seedling"></i>
      </div>
      <div *ngIf="isAdmin()" class="sidebar-brand-text mx-3">Grainsmart</div>
    </a>

    <!-- Divider -->
    <hr class="sidebar-divider my-0" />

    <!-- Nav Item - Dashboard -->
    <li class="nav-item active">
      <a class="nav-link" routerLink="home">
        <i class="fas fa-fw fa-tachometer-alt"></i>
        <span> Dashboard</span></a
      >
    </li>

    <div *ngFor="let nav of service.navValues; let i = index">
      <!-- Divider -->
      <hr class="sidebar-divider" />

      <!-- Heading -->
      <div class="sidebar-heading">
        {{ nav.group }}
      </div>

      <!-- Nav Item - Pages Collapse Menu -->
      <li class="nav-item" *ngFor="let con of nav.contents; let x = index">
        <a
          class="nav-link collapsed"
          href="#"
          data-toggle="collapse"
          [attr.data-target]="'#collapse_' + con.tag"
          aria-expanded="true"
          aria-controls="collapse_account"
        >
          <i class="fas fa-fw fa-{{ con.icon }}"></i>
          <span> {{ con.section }}</span>
        </a>
        <div
          id="collapse_{{ con.tag }}"
          class="collapse show"
          aria-labelledby="headingPages"
          data-parent="#accordionSidebar"
        >
          <div class="bg-white py-2 collapse-inner rounded">
            <h6 class="collapse-header">Pages:</h6>
            <div *ngFor="let page of con.pages; let i = index" [class.disabled]="page.disabled">
              <a class="collapse-item" [routerLink]="page.path">
                <!-- <i class="fas fa-fw fa-users"></i> -->
                {{ page.label }}
              </a>

              <a
                *ngFor="let sub of page.sub; let i = index"
                class="collapse-item"
                [routerLink]="sub.path"
                [class.disabled]="page.disabled"
              >
                - {{ sub.label }}
              </a>
            </div>
          </div>
        </div>
      </li>
    </div>

    <!-- Divider -->
    <hr class="sidebar-divider d-none d-md-block" />

    <!-- Sidebar Toggler (Sidebar) -->
    <div class="text-center d-none d-md-inline">
      <button class="rounded-circle border-0" id="sidebarToggle"></button>
    </div>
  </ul>

  <div id="content-wrapper" class="d-flex flex-column">
    <!-- Main Content -->
    <div id="content">
      <!-- Topbar -->
      <nav
        class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow"
      >
        <!-- Sidebar Toggle (Topbar) -->
        <form class="form-inline">
          <button
            id="sidebarToggleTop"
            class="btn btn-link d-md-none rounded-circle mr-3"
          >
            <i class="fa fa-bars"></i>
          </button>
        </form>

        <div>
          <h3>{{ getTradeName() }}</h3>
        </div>

        <!-- Topbar Search -->
        <!--<form
          class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search"
        >
          <div class="input-group">
            <input
              type="text"
              class="form-control bg-light border-0 small"
              placeholder="Search for..."
              aria-label="Search"
              aria-describedby="basic-addon2"
            />
            <div class="input-group-append">
              <button class="btn btn-primary" type="button">
                <i class="fas fa-search fa-sm"></i>
              </button>
            </div>
          </div>
        </form>-->

        <!-- Topbar Navbar -->
        <ul class="navbar-nav ml-auto">
          <!-- Nav Item - Search Dropdown (Visible Only XS) -->
          <li class="nav-item dropdown no-arrow d-sm-none">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="searchDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-search fa-fw"></i>
            </a>
            <!-- Dropdown - Messages -->
            <div
              class="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
              aria-labelledby="searchDropdown"
            >
              <form class="form-inline mr-auto w-100 navbar-search">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control bg-light border-0 small"
                    placeholder="Search for..."
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                  />
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="button">
                      <i class="fas fa-search fa-sm"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li>

          <!-- Nav Item - Alerts -->
          <!--
          <li class="nav-item dropdown no-arrow mx-1">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="alertsDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-bell fa-fw"></i>
              
              <span class="badge badge-danger badge-counter">3+</span>
            </a>
            
            <div
              class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="alertsDropdown"
            >
              <h6 class="dropdown-header">
                Alerts Center
              </h6>
              <a class="dropdown-item d-flex align-items-center" href="#">
                <div class="mr-3">
                  <div class="icon-circle bg-primary">
                    <i class="fas fa-file-alt text-white"></i>
                  </div>
                </div>
                <div>
                  <div class="small text-gray-500">December 12, 2019</div>
                  <span class="font-weight-bold"
                    >A new monthly report is ready to download!</span
                  >
                </div>
              </a>
              <a class="dropdown-item d-flex align-items-center" href="#">
                <div class="mr-3">
                  <div class="icon-circle bg-success">
                    <i class="fas fa-donate text-white"></i>
                  </div>
                </div>
                <div>
                  <div class="small text-gray-500">December 7, 2019</div>
                  $290.29 has been deposited into your account!
                </div>
              </a>
              <a class="dropdown-item d-flex align-items-center" href="#">
                <div class="mr-3">
                  <div class="icon-circle bg-warning">
                    <i class="fas fa-exclamation-triangle text-white"></i>
                  </div>
                </div>
                <div>
                  <div class="small text-gray-500">December 2, 2019</div>
                  Spending Alert: We've noticed unusually high spending for your
                  account.
                </div>
              </a>
              <a class="dropdown-item text-center small text-gray-500" href="#"
                >Show All Alerts</a
              >
            </div>
          </li>
        -->

          <div class="topbar-divider d-none d-sm-block"></div>

          <!-- Nav Item - User Information -->
          <li class="nav-item dropdown no-arrow">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{
                getUsername()
              }}</span>
              <img
                class="img-profile rounded-circle"
                src="https://source.unsplash.com/QAB-WJcbgJk/60x60"
              />
            </a>
            <!-- Dropdown - User Information -->
            <div
              class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              <a class="dropdown-item" [routerLink]="'user/' + getUsername()">
                <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                Profile
              </a>
              <a class="dropdown-item" href="#">
                <i class="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                Activity Log
              </a>
              <div class="dropdown-divider"></div>
              <a
                class="dropdown-item"
                href="#"
                data-toggle="modal"
                data-target="#logoutModal"
                (click)="logout()"
              >
                <i
                  class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"
                ></i>
                Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
      <!-- End of Topbar -->

      <!-- Begin Page Content -->
      <div class="container-fluid">
        <router-outlet></router-outlet>
        <app-toasts></app-toasts>
        <ngx-spinner></ngx-spinner>
      </div>
      <!-- /.container-fluid -->
    </div>
    <!-- End of Main Content -->

    <!-- Footer -->
    <footer class="sticky-footer bg-white">
      <div class="container my-auto">
        <div class="copyright text-center my-auto">
          <span>PayRetail &copy; Grainsmart {{ year }}</span>
        </div>
      </div>
    </footer>
    <!-- End of Footer -->
  </div>
</div>
